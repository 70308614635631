import React from "react"
import { AuthProvider } from "./src/context/auth-context"
import "./src/styles/global.css"
import "./static/assets/fonts/flaticon/flaticon.css"
import "./static/assets/fonts/fontawesome/fontawesome-all.min.css"
import "./static/assets/vendor/bootstrap/css/bootstrap.min.css"
import "./static/assets/css/style.css"
import "./static/assets/custom.css"
import "./static/assets/css/plugins.css"
import "./static/assets/styles/maincolors.css"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapPageElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lfkp68qAAAAABUBNxCv0aG0iEkpbRwupEKasI3K">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>{element}</AuthProvider>
      </LocalizationProvider>
    </GoogleReCaptchaProvider>
  )
}
